.map {
    width: 100%;
    position: relative;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.map.map_disabled-ui .mapboxgl-control-container {
    display: none;
}

.map .mapboxgl-ctrl-bottom-right {
    display: none;
}

.map .custom-marker {
    cursor: pointer;
}
