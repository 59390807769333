.list_item {
    width: calc(25% - 6px);
    cursor: pointer;
    position: relative;
}

.list_item::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background: #00000099;
}

.chip {
    position: absolute;
    top: 12px;
    left: 12px;
}

.icon {
    position: absolute;
    top: 8px;
    left: 80%;
}

.overlay,
.icon {
    opacity: 0;
    pointer-events: none;
    transition: 0.15s ease;
}

.img_active .overlay,
.img_active .icon {
    opacity: 1;
    pointer-events: auto;
}

.img:hover .overlay,
.img:hover .icon {
    opacity: 1;
    pointer-events: auto;
}
